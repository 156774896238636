import React, { useState, useEffect } from 'react'

import { Divider } from 'primereact/divider'; //для полоски с "или"
import { Toast } from 'primereact/toast'; //использовать для сообщений от бэка
        
import './style.scss'

const Login = () => {
    return (
        <div className='login'>
            Login
        </div>
    )
}

export default Login